/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  GroupIcon,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import Edit from "@material-ui/icons/Edit";
import FormatShapes from "@material-ui/icons/FormatShapes";
import Share from "@material-ui/icons/Share";

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const versionTextStyle = makeStyles({
  h1: {
    color: "white",
    marginLeft: 134,
    marginTop: -18,
    fontSize: 12,
  }
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const SidebarVersion = () => {
  return (
      <div>
        <h1 className={versionTextStyle().h1}>1.19.0</h1>
      </div>

  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarVersion />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={WidgetsRoundedIcon} to="catalog?filters%5Bkind%5D=component&filters%5Btype%5D=library&filters%5Buser%5D=all" text='Libraries'/>
        <SidebarItem icon={GroupIcon} to="catalog/default/group/smgrealestate" text="Groups" />
        <SidebarItem icon={Share} to="catalog-graph?rootEntityRefs%5B%5D=group%3Adefault%2Fsmgrealestate&maxDepth=∞&selectedKinds%5B%5D=api&selectedKinds%5B%5D=component&selectedKinds%5B%5D=group&selectedKinds%5B%5D=location&selectedKinds%5B%5D=resource&unidirectional=true&mergeRelations=true&direction=TB&showFilters=true" text="Graph" />
        <SidebarItem icon={FormatShapes} to="catalog/default/component/smg-re-adr/docs" text="ADR" />
        <SidebarItem icon={DeveloperMode} to="catalog/default/component/architecture/docs/" text="Architecture" />
        <SidebarItem icon={Edit} to="catalog/default/component/backstage-documentation/docs" text="Contribute ..." />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={BrokenImageIcon} to="catalog-unprocessed-entities" text="Unprocessed" />
        </SidebarScrollWrapper>
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
