import {
    RadarEntry,
    RadarQuadrant,
    RadarRing,
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import {parse} from "csv-parse/sync";
import {Config} from "@backstage/config";

export class TechRadarClient implements TechRadarApi {

    config: Config;

    constructor(config: Config) {
        this.config = config
    }

    async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
        // if needed id prop can be used to fetch the correct data

        const csvData = await fetch(`${this.config.getString('backend.baseUrl')}/api/proxy/homegateradar/TechRadar-Homegate.csv`).then(response => response.text());

        const entries = parse(csvData, { delimiter: ','})
        entries.shift();

        const dataEntries = new Array<RadarEntry>();

        for (let i=0; i < entries.length; i++) {

            const entry = entries[i];
            dataEntries.push({
                timeline: [
                    {
                        moved: 0,
                        ringId: entry[1],
                        date: new Date('2020-08-06'),
                    },
                ],
                url: '#',
                key: entry[0],
                id: entry[0],
                title: entry[0],
                quadrant: entry[2],
                description: entry[4],
            });
        }

        const rings = new Array<RadarRing>();
        rings.push({ id: 'adopt', name: 'ADOPT', color: '#93c47d' });
        rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
        rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
        rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

        const quadrants = new Array<RadarQuadrant>();
        quadrants.push({ id: 'platforms', name: 'platforms' });
        quadrants.push({ id: 'languages & frameworks', name: 'languages & frameworks' });
        quadrants.push({ id: 'tools', name: 'tools' });
        quadrants.push({ id: 'techniques', name: 'techniques' });

        return {
            quadrants: quadrants,
            rings: rings,
            entries: dataEntries
        };
    }
}
